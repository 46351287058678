<fieldset class="annotation-table">
  <legend>
    Gene structure history

    <app-help-link [route]="'/documentation/gene-page-gene-structure-history'"
      [title]="'Click to view documentation for Gene structure history'">
    </app-help-link>
  </legend>
  <table>
    <thead>
      <tr>
        <th>
          Used until
        </th>
        <th>
          Gene structure
        </th>
        <th>
          Reference
        </th>
        <th>
          Comment
        </th>
        <th>
          Genome snapshot
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let entry of processedHistory">
        <td>
          {{entry.date}}
        </td>
        <td>
          {{entry.previous_coords}}
        </td>
        <td>
          <span *ngFor="let referenceParts of entry.processedReferences">
            <span *ngFor="let ref of referenceParts">
              <span *ngIf="ref.text">
                {{ref.text}}
              </span>
              <span *ngIf="ref.referenceUniquename">
                <a routerLink="/reference/{{ref.referenceUniquename}}">{{ref.referenceUniquename}}</a>
              </span>
            </span>
          </span>
        </td>
        <td>
          <span *ngIf="entry.comments">{{entry.comments}}</span>
        </td>
        <td>
          <a *ngIf="entry.genome_snapshot_link"
             title="The most recent data file before this change"
             href="{{entry.genome_snapshot_link}}">{{genomeSnapshotLabel(entry)}}</a>
        </td>
      </tr>
      <tr>
        <td>Now</td>
        <td>{{currentStructure()}}</td>
      </tr>
    </tbody>

  </table>
</fieldset>
