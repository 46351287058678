<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Download options</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tabset #downloadTabs>
      <tab heading="Tab delimited">
        <div class="field-container" [ngStyle]="fieldContainerStyle()">

        <div class="field-input" *ngFor="let fieldName of getVisibleFieldNames()">
          <input  id="name-{{fieldName}}" type="checkbox" [(ngModel)]="selectedFields[fieldName]"
                  (ngModelChange)="fieldChange(fieldName)"/>
          <label for="name-{{fieldName}}">{{fieldConfigByName[fieldName].display_name}}</label>
        </div>

        <div></div>

        <div class="actions" *ngIf="!allFieldsAreVisible()">
          <a (click)="showAllFields()">Show gene expression columns</a>
        </div>

        <div class="actions select-all-action">
          <a (click)="selectAll()">Select all</a>
        </div>

        <div class="actions">
          <a (click)="resetSelection()">Reset selection</a>
        </div>

        </div>
      </tab>

      <tab heading="FASTA sequence" (select)="tabSelected($event)">
      <div>
      <div class="seq-column">
        <div>Type:</div>
        <div class="seq-control">
          <input id="protein" type="radio" [(ngModel)]="seqType" value="protein"/>
          <label for="protein">Protein</label>
        </div>
        <div>
          <input id="nucleotide" type="radio" [(ngModel)]="seqType" value="nucleotide"/>
          <label for="nucleotide">Nucleotide</label>
          <div class="nucleotide-controls" *ngIf="seqType == 'nucleotide'">
            <div class="title">
              Include in downloaded sequences:
            </div>

            <div class="flanking-bases">
              <input id="upstream-bases" type="number" min="0" value="0"
                     [(ngModel)]="upstreamBases" (ngModelChange)="nucControlChange()"/>
              <label for="upstream-bases">bases upstream</label>
            </div>
            <div>
              <input id="include-5-prime" type="checkbox" [(ngModel)]="include5PrimeUtr"
                     (ngModelChange)="nucControlChange()"/>
              <label for="include-5-prime">5'UTR (protein-coding genes only)</label>
            </div>
            <div>
              <input id="include-exons" type="checkbox" [disabled]="disableIncludeExons"
                     [attr.title]="disableIncludeExons ? 'Select some up/down stream bases, UTRs or introns for download to enable this toggle' : ''"
                     [(ngModel)]="includeExons" (ngModelChange)="nucControlChange()"/>
              <label for="include-exons">Exons</label>
            </div>
            <div>
              <input id="include-introns" type="checkbox" [(ngModel)]="includeIntrons"
                     (ngModelChange)="nucControlChange()"/>
              <label for="include-introns">Introns</label>
            </div>
            <div>
              <input id="include-3-prime" type="checkbox" [(ngModel)]="include3PrimeUtr"
                     (ngModelChange)="nucControlChange()"/>
              <label for="include-3-prime">3'UTR (protein-coding genes only)</label>
            </div>
            <div class="flanking-bases">
              <input id="downstream-bases" type="number" min="0" value="0"
                     [(ngModel)]="downstreamBases" (ngModelChange)="nucControlChange()"/>
              <label for="downstream-bases">bases downstream</label>
            </div>
          </div>
        </div>
        </div>
        <div class="field-column">
           <div>Include in headers:</div>
           <div class="field-container" [ngStyle]="fieldContainerStyle()">
           <div class="field-input" *ngFor="let fieldName of getVisibleFieldNames()">
             <input id="name-{{fieldName}}" type="checkbox" [(ngModel)]="selectedFields[fieldName]"
                    [disabled]="fieldName === 'Systematic ID'"
                    (ngModelChange)="fieldChange(fieldName)"/>
             <label for="name-{{fieldName}}">{{fieldConfigByName[fieldName].display_name}}</label>
           </div>

           <div></div>

           <div class="actions" *ngIf="!allFieldsAreVisible()">
            <a (click)="showAllFields()">Show gene expression columns</a>
          </div>

           <div class="select-all-action">
             <a (click)="selectAll()">Select all</a>
           </div>

           <div>
             <a (click)="resetSelection()">Reset selection</a>
           </div>

           </div>
        </div>
        </div>
      </tab>

      <tab heading="GO Annotations">
        <div class="field-container gaf-container">
          <div class="gaf-title">Choose the GO aspects to download:</div>
          <div class="field-input" *ngFor="let aspect of appConfig.goAspects">
            <input  id="name-{{aspect}}" type="checkbox" [(ngModel)]="selectedAspects[aspect]"/>
            <label for="name-{{aspect}}">{{aspectDisplayName(aspect)}}</label>
          </div>
        </div>
        <div class="gaf-help">
Use this tab to download Gene Ontology annotation for the genes in
your result set.
<p>
The annotation will be in <a href="http://geneontology.org/docs/go-annotation-file-gaf-format-2.1/">
Gene Association File ("GAF") format</a>.
        </div>
      </tab>
    </tabset>
 </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!isValid()"
            [attr.title]="downloadTitle()"
            (click)="download()">Download</button>
  </div>
</div>
