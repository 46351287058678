<div>
    <app-loading-spinner *ngIf="!alleleDetails && !apiError"></app-loading-spinner>
    <app-api-error-display *ngIf="apiError" [error]="apiError"></app-api-error-display>
    <div id="allele-details" *ngIf="alleleDetails && !apiError">
      <app-social-contact></app-social-contact>
      <div class="details-page-name-and-id">
        <span class="details-page-name-and-id-prefix">Allele:</span>
        <span class="name-id-highlight" [innerHTML]="displayName"></span> ({{alleleDetails.uniquename}})
      </div>
      <div id="allele-summary" class="details-page-summary">
        <div class="details-label">Name</div>
        <div>{{alleleDetails.name}}</div>
        <div *ngIf="alleleDetails.description" class="details-label">Description</div>
        <div *ngIf="alleleDetails.description">{{alleleDetails.description}}</div>
        <div class="details-label">Type</div>
        <div>{{alleleDetails.allele_type}}</div>
        <div *ngIf="synonymString" class="details-label">Synonyms</div>
        <div *ngIf="synonymString">{{synonymString}}</div>
        <div *ngIf="alleleDetails.comments.length > 0" class="details-label">Comment</div>
        <div *ngIf="alleleDetails.comments.length > 0">
          <div *ngFor="let commentDetails of alleleDetails.comments">
            {{commentDetails.comment}}
            <span *ngIf="commentDetails.reference">
              (<a routerLink="/reference/{{commentDetails.reference}}">{{commentDetails.reference}}</a>)
            </span>
          </div>
        </div>
        <div class="details-label">Gene</div>
        <div><a routerLink="/gene/{{alleleDetails.gene.uniquename}}">{{geneDisplayName}}</a></div>
      </div>

      <div class="obsolete-warning" *ngIf="alleleDetails.is_obsolete">
        Warning: this allele is not currently used in {{siteName}}
      </div>

      <div class="all-alleles-link">
        View <a routerLink="/gene_alleles/{{alleleDetails.gene.uniquename}}">all alleles of {{geneDisplayName}}</a>
      </div>

      <div class="allele-flex-container">
      <div class="allele-details-genotypes">
        <div *ngIf="singleLocusGenotypes.length != 0">
          <h5>
            Single locus genotypes:
          </h5>
          <div *ngFor="let genotypeObj of singleLocusGenotypes">
            <app-genotype-link [genotype]="genotypeObj.genotypeShort"></app-genotype-link>
          </div>
        </div>
        <div *ngIf="multiLocusGenotypes.length != 0">
          <h5>
            Multi locus genotypes:
          </h5>
          <div *ngFor="let genotypeObj of multiLocusGenotypes">
            <app-genotype-link [genotype]="genotypeObj.genotypeShort"></app-genotype-link>
          </div>
        </div>
      </div>

      <div class="allele-details-phenotypes">
        <div *ngIf="alleleDetails.phenotypes && alleleDetails.phenotypes.length > 0">
          <h5>Phenotypes of <span class="name-id-highlight" [innerHTML]="displayName"></span>:</h5>
          <div *ngFor="let phenotype of alleleDetails.phenotypes">
            <app-term-link [term]="phenotype">
              {{phenotype.name}}
            </app-term-link>
          </div>
        </div>
      </div>
    </div>
   </div>
</div>

