<div id="jbrowse_tracks" *ngIf="hasTracks()">
  <div class="jbrowse-header">
Genome browser tracks
  <span *ngIf="identifierType == 'gene'">associated with this gene:</span> 
  <span *ngIf="identifierType == 'reference'">from this publication:</span>
  </div>
<fieldset class="jbrowse-picker annotation-table">
  <legend>
    <span *ngIf="tracks.length == 1">
      A dataset
      <span *ngIf="identifierType == 'gene'">associated with {{identifier}}</span>
      <span *ngIf="identifierType == 'reference'">from this publication</span>
      is available in the genome browser</span>
    <span *ngIf="tracks.length != 1">{{tracks.length}} datasets
      <span *ngIf="identifierType == 'gene'">associated with {{identifier}}</span>
      <span *ngIf="identifierType == 'reference'">from this publication</span>
      are available in the genome browser</span>

  </legend>

  <div *ngIf="tracksVisible">
    <div class="select-tracks-help">
      <div>
        <div>
          Choose tracks to view
        </div>
        <div>
          Select: <a (click)="selectAll()">all</a> | <a (click)="selectNone()">none</a>
        </div>
      </div>

      <button class="btn btn-primary load-in-jbrowse"
              [attr.title]="selectedCount() == 0 ? 'Select some tracks from the list below' : 'View the selected tracks in JBrowse'"
              [disabled]="loadButtonDisabled()"
              (click)="loadInJBrowse()">Load in JBrowse</button>
    </div>

    <table *ngIf="tracksVisible">
      <thead>
        <tr>
          <th>

          </th>
          <th *ngFor="let col of colConfig">
            <div *ngIf="tracks.length != 1">
              <a *ngIf="orderByFieldName != '+' + col.name && !col.download_link" (click)="setOrderBy(col.name)"
                 title="Click to sort by {{col.display_name}}"><img src="assets/sort_both.svg"/>{{col.display_name}}</a>
              <span class="order-column" title="Sorted by: {{col.display_name}}"
                    *ngIf="orderByFieldName == '+' + col.name && !col.download_link"><img src="assets/sort_up.svg"/>{{col.display_name}}</span>
            </div>
            <div *ngIf="tracks.length == 1">
              {{col.display_name}}
            </div>
          </th>
        </tr>
      </thead>
      <tr *ngFor="let track of tracks | jbrowseTrackOrderBy: orderByFieldName">
        <td>
          <input id="track.label" type="checkbox"
                 [attr.title]="selectCheckboxesDisabled() ? 'JBrowse track limit reached' : 'Check box to select this track for JBrowse'"
                 [disabled]="selectCheckboxesDisabled() && !selectedTracks[track.label]"
                 [(ngModel)]="selectedTracks[track.label]"
                 (ngModelChange)="trackSelected(track.label)"
                 title="Select / unselect track"/>
        </td>

        <td *ngFor="let col of colConfig">
          <a class="download-link" *ngIf="col.download_link" href="{{track[col.name]}}">Download ...</a>
          <span *ngIf="!col.download_link">{{track[col.name] || ''}}</span>
        </td>
      </tr>
    </table>
  </div>
</fieldset>
</div>
