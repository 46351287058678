<ng-template #annotationHelpPopover>
  <div class="help-popover">
    {{title}}
  </div>
</ng-template>

<span class="help-link">

  <a [popover]="annotationHelpPopover"
     triggers="mouseenter:mouseleave" placement="bottom"
     container="body"
     routerLink="{{route}}"><img src="/assets/info_icon.svg">
    <ng-content></ng-content>
  </a>
</span>
