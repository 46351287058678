<fieldset class="annotation-table">
  <legend>
    {{annotationTypeDisplayName}}

    <app-help-link [route]="'/documentation/orthologs'"
                   [title]="'Click to view documentation for Orthologs'">
    </app-help-link>
  </legend>

  <div *ngIf="annotationTable && annotationTable.length > 0"
       class="ortholog-table annotation-table-container">
    <div class="title">
      Manually assigned orthologs
    </div>

  <table>
    <thead>
      <tr>
        <th *ngIf="!currentGene">Gene</th>
        <th *ngIf="!currentGene">Product</th>
        <th>Ortholog species</th>
        <th>Ortholog gene</th>
        <th>Ortholog description</th>
        <th *ngIf="!hideColumn['qualifier']">Qualifier</th>
        <th *ngIf="!hideColumn['reference']">Reference</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let annotation of displayTable">
        <td *ngIf="!currentGene">
          <app-gene-link class="app-link" [long]="false" [gene]="annotation.gene"></app-gene-link>
        </td>
        <td *ngIf="!currentGene">{{annotation.gene.product}}</td>
        <td>{{annotation.orthologOrganism.genus}} {{annotation.orthologOrganism.species}}</td>
        <td>
          <span class="ortholog-gene">
          <app-gene-link *ngIf="appConfig.isConfigOrganism(annotation.orthologOrganism.taxonid)"
                         class="app-link" [long]="false" [gene]="annotation.ortholog"></app-gene-link>
          <a *ngIf="!appConfig.isConfigOrganism(annotation.orthologOrganism.taxonid)"
              href="{{getLink(annotation.orthologOrganism, annotation.ortholog.uniquename, annotation.ortholog.name)!}}">
            {{annotation.ortholog.name ? annotation.ortholog.name + " (" + annotation.ortholog.uniquename + ")" : annotation.ortholog.uniquename }}
          </a>
          </span>
        </td>
        <td>
          <span *ngIf="annotation.orthologFullProduct">
            <span title="{{annotation.orthologFullProduct}}">{{annotation.orthologShortProduct}}</span>
            <a (click)="showFullProduct(annotation.ortholog.uniquename, annotation.orthologFullProduct)"
               *ngIf="annotation.orthologFullProduct != annotation.orthologShortProduct">
              more ...
            </a>
          </span>
        </td>
        <td class="ortholog-qualifier" *ngIf="!hideColumn['qualifier']">
         {{annotation.qualifier || ''}}
        </td>
        <td class="ortholog-reference" *ngIf="!hideColumn['reference']">
          <span *ngIf="annotation.reference">
            <app-reference-short [reference]="annotation.reference"></app-reference-short>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  </div>

  <div class="external-refs" *ngIf="displayOrthologPredictionResources()">
    <div class="title">
      Ortholog prediction resources
    </div>

    <div class="ref-table">
      <app-external-refs-table [geneDetails]="currentGene" [sectionFilter]="'orthologs'">
      </app-external-refs-table>
    </div>
  </div>
</fieldset>
