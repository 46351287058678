<div class="node-boolean" *ngIf="isBooleanNode()">

  <div *ngFor="let part of nodeAsBooleanNode().getParts(); let idx = index">
    <app-gene-query-structure [node]="part" [level]="level+1" (gotoResults)="returnResults($event)"></app-gene-query-structure>
    <div class="boolean-operator" *ngIf="idx != nodeAsBooleanNode().getParts().length - 1">
      <a *ngIf="level > 0" (click)="queryFromNode()"
         [title]="'click to view results for query: ' + node.detailsString()">{{nodeAsBooleanNode().opString()}}</a>
      <span *ngIf="level == 0">{{nodeAsBooleanNode().opString()}}</span>
    </div>
  </div>

</div>

<div class="node-details" *ngIf="!isBooleanNode()">
  <a [title]="'click to view results query: ' + node.detailsString()" (click)="queryFromNode()">{{node.detailsString()}}</a>
</div>
