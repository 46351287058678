<div class="stats-summary">
  <div>
    <div *ngIf="dbCreationDate" class="db-creation-date">Last updated: {{dbCreationDate}}</div>
    <div *ngIf="curatedPublicationsCount != 0">
      {{curatedPublicationsCount}} curated publications
      <ul *ngIf="stats">
        <li *ngIf="stats.community_pubs_count > 0">
          Community: <a routerLink="/reference_list/community">{{stats.community_pubs_count}}</a>
        </li>
        <li *ngIf="stats.non_community_pubs_count > 0">
          {{appConfig.site_name}}: <a routerLink="/reference_list/admin">{{stats.non_community_pubs_count}}</a>
        </li>
      </ul>
    </div>
  </div>

</div>
