<span *ngIf="choiceData.length != 0" class="annotation-table-filter">
  {{config.display_name}}

  <select [(ngModel)]="selectedCategory" (ngModelChange)="setCategory($event)" title="Select a term">
    <option [ngValue]="null">No filter</option>
    <option *ngFor = "let category of choiceData"
            [disabled]="!category.active" [ngValue]="category">
      {{category.displayName}}
    </option>
  </select>
</span>

<span *ngIf="choiceData.length == 0" class="annotation-table-filter term-filter-no-choices">
  (No {{config.display_name.toLocaleLowerCase()}}s)
</span>
