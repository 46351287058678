<ng-template #identifierPopover>
  <div class="popover-identifier">{{popoverIdentifier}}</div>
  <div *ngIf="identifierSourceDescription">Click for details at: {{identifierSourceDescription}}</div>
</ng-template>


<span>
  <app-gene-link class="app-link" *ngIf="gene" [long]="false" [gene]="gene">
  </app-gene-link>


  <span *ngIf="transcript">
    <span *ngIf="transcript.gene">
      {{transcript.uniquename}}
      (<app-gene-link  class="app-link" [long]="false" [gene]="transcript.gene"></app-gene-link>)
    </span>
    <a *ngIf="!transcript.gene" routerLink="/gene/{{transcript.gene_uniquename}}">{{transcript.uniquename}}</a>
  </span>


  <a *ngIf="term"
     routerLink="/term/{{term.termid}}" routerLinkActive="active">{{term.name}}</a>

  <span
     *ngIf="identifier"
     #link="bs-popover"
     [popover]="identifierPopover"
     triggers=""
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()"
     placement="bottom" container="body">
    <a class="with-from-id" *ngIf="linkUrl" href="{{linkUrl}}">{{displayIdentifier}}</a>
    <span class="with-from-id" *ngIf="!linkUrl">{{displayIdentifier}}</span>
  </span>
</span>
