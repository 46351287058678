<span>
  {{config.display_name}}

  <select [(ngModel)]="selectedType" (ngModelChange)="setType($event)">
    <option [ngValue]="null">No filter</option>
    <option *ngFor="let category of choiceData" [disabled]="!category.active" [ngValue]="category">
      {{category.interactionType}}
    </option>
  </select>
</span>
