<div>
   <span class="filters-header">Filters:</span>
   <span class="filter-control" *ngFor="let config of filters">
     <app-interaction-table-throughput-filter
       *ngIf="config.filter_type == 'throughput'"
       [config]="config"
       [interactionTable]="interactionTable"
       (filterChange)="filterChanged('throughput', $event)">
     </app-interaction-table-throughput-filter>
   </span>
 </div>

