<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Visible table columns</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="field-container" [ngStyle]="fieldContainerStyle()">
    <div *ngFor="let field of visibleFields">
      <input id="name-{{field.name}}" type="checkbox" [(ngModel)]="selectedFieldNames[field.name]"
             (ngModelChange)="fieldChange(field.name)"/>
      <label for="name-{{field.name}}">{{field.display_name}}</label>
    </div>
    <div class="actions" *ngIf="!allFieldsAreVisible()">
      <a (click)="showAllFields()">Show gene expression columns</a>
    </div>
    <div class="actions">
      <a (click)="resetSelection()">Reset selection</a>
    </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="apply()">Apply</button>
  </div>
</div>
