<section class="help-section">
  <h4>Search examples</h4>
  <table width="100%" border="0">
    <tbody>
      <tr>
        <td class="pep">CADR</td>
        <td>will find</td>
        <td>any protein containing <span class="pep">CADR</span></td>
      </tr>
      <tr>
        <td class="pep">CA[DE]R</td>
        <td>will find</td>
        <td>any protein containing <span class="pep">CADR</span> or <span class="pep">CAER</span></td>
      </tr>
      <tr>
        <td class="pep">CA[DE]+LQ</td>
        <td>will find</td>
        <td><span class="pep">CA</span>(any sequence of <span class="pep">D</span> and <span class="pep">E</span>)<span class="pep">LQ</span></td>
      </tr>
      <tr>
        <td class="pep">CA...R</td>
        <td> will find</td>
        <td><span class="pep">CA</span>(any three amino acids)<span class="pep">R</span></td>
      </tr>
      <tr>
        <td class="pep">CA.+R </td>
        <td>will find </td>
        <td><span class="pep">CA</span>(one or more amino acids)<span class="pep">R</span></td>
      </tr>
      <tr>
        <td class="pep">SPR.|SP.R</td>
        <td>will find </td>
        <td><span class="pep">SPRX</span> or <span class="pep">SPXR</span></td>
      </tr>
      <tr>
        <td class="pep">^ME </td>
        <td>will find </td>
        <td>proteins beginning with <span class="pep">ME</span></td>
      </tr>
      <tr>
        <td class="pep">LAA$</td>
        <td>will find </td>
        <td>proteins terminating <span class="pep">LAA</span></td>
      </tr>
      <tr>
        <td class="pep">^.{{ '{1,20}MCA' }}</td>
        <td>will find </td>
        <td>proteins with <span class="pep">MCA</span> in the first 20 amino acids</td>
      </tr>
    </tbody>
  </table>
</section>

<section class="help-section">
  <h4 class="aa-header">
    Amino acid group codes
  </h4>
  <div class="aa-example">
    <table>
      <tr>
        <td style="padding-right: 0.5em">
          Examples:
        </td>
        <td>
          <span class="pep">CA6AC</span> will find
          <span class="pep">CALAC</span> and <span class="pep">CAFAC</span>
        </td>
      </tr>
      <tr>
        <td></td>
        <td><span class="pep">CA0+LQ</span> will find
          <span class="pep">CA</span>(any sequence of <span class="pep">D</span> or <span class="pep">E</span>)<span class="pep">LQ</span>
        </td>
      </tr>
    </table>
  </div>
  <div style="width: 30em">
    <table width="100%" border="0">
      <tbody><tr>
          <th align="left">AA group</th>
          <th align="left">Code</th>
          <th align="left">Amino acids</th>
        </tr>
        <tr>
          <td>acidic </td>
          <td>0 </td>
          <td class="pep">DE</td>
        </tr>
        <tr>
          <td>alcohol </td>
          <td>1 </td>
          <td class="pep">ST</td>
        </tr>
        <tr>
          <td>aliphatic </td>
          <td>2 </td>
          <td class="pep">AGILV</td>
        </tr>
        <tr>
          <td>aromatic </td>
          <td>3 </td>
          <td class="pep">FHWY</td>
        </tr>
        <tr>
          <td>basic </td>
          <td>4 </td>
          <td class="pep">KRH</td>
        </tr>
        <tr>
          <td>charged </td>
          <td>5 </td>
          <td class="pep">DEHKR</td>
        </tr>
        <tr>
          <td>hydrophobic </td>
          <td>6 </td>
          <td class="pep">AVILMFYW</td>
        </tr>
        <tr>
          <td>hydrophilic </td>
          <td>7 </td>
          <td class="pep">KRHDENQ</td>
        </tr>
        <tr>
          <td>polar </td>
          <td>8 </td>
          <td class="pep">CDEHKNQRST</td>
        </tr>
        <tr>
          <td>small </td>
          <td>9 </td>
          <td class="pep">ACDGNPSTV</td>
        </tr>
        <tr>
          <td>tiny </td>
          <td>B </td>
          <td class="pep">AGS</td>
        </tr>
        <tr>
          <td>turnlike </td>
          <td>Z </td>
          <td class="pep">ACDEGHKNQRST</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
