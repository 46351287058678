<ng-template #refPopover>
  <div *ngFor="let content of popoverContents">{{content}}</div>
  <div class="link-hint" *ngIf="hasAnnotation">(click to view all annotation from this reference)</div>
</ng-template>
<span #link="bs-popover"
      class="reference-short-link" [popover]="refPopover"
      triggers=""
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseLeave()"
      container="body"
      placement="bottom">
  <span *ngIf="!xref">
    <a *ngIf="refTitle" routerLink="/reference/{{reference.uniquename}}">{{refTitle}}</a>
    <a *ngIf="!refTitle" routerLink="/reference/{{reference.uniquename}}">{{displayString}}</a>
  </span>
  <a *ngIf="xref" href="{{xref}}">{{displayString}}</a>
</span>
