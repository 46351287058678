<div>
  <h2 *ngIf="pageTitle" class="reference-page-title">
    {{pageTitle}}
  </h2>

  <div class="other-ref-page">
    <a *ngIf="constraint == 'community' && hasAdminCuration" routerLink="/reference_list/admin">View {{siteName}} curated publications ...</a>
    <a *ngIf="constraint == 'admin'" routerLink="/reference_list/community">View community curated publications ...</a>
  </div>


  <div class="ref-group" *ngFor="let group of groups">
    <div class="ref-group-header">
      {{group.title}}
    </div>
    <div class="ref-group-list">
      <ul>
        <li class="short-ref" *ngFor="let ref of group.refList">
          <a [title]="refTitle(ref)"
             routerLink="/reference/{{ref.uniquename}}">{{ref.title}}</a>
          {{extraDisplayString(ref)}}
        </li>
      </ul>
    </div>
  </div>
</div>
