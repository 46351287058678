<div class="query-history" *ngIf="sortedHistoryEntries.length > 0">
  <div class="title">Combine queries:</div>

  <div class="content">
    <span class="op-buttons">
    <span [style.display]="getSelectedEntries().length < 2 ? 'inherit' : 'none'">
      <button class="btn btn-primary" disabled title="Select two or more queries to union">Union / or <img class="bool-op-image" src="assets/disabled_operator_or.png"/></button>
      <button class="btn btn-primary" disabled title="Select two or more queries to intersect">Intersect / and <img class="bool-op-image" src="assets/disabled_operator_and.png"/></button>
    </span>
    <span [style.display]="getSelectedEntries().length >= 2 ? 'inherit' : 'none'">
      <button class="btn btn-primary" (click)="action('or')">Union / or <img class="bool-op-image" src="assets/operator_or.png"/></button>
      <button class="btn btn-primary" (click)="action('and')">Intersect / and <img class="bool-op-image" src="assets/operator_and.png"/></button>
    </span>
    <span [style.display]="getSelectedEntries().length != 2 ? 'inherit' : 'none'">
      <button class="btn btn-primary" disabled title="Select two query for subtract">Subtract / not <img class="bool-op-image" src="assets/disabled_operator_not.png"/></button>
    </span>
    <span [style.display]="getSelectedEntries().length == 2 ? 'inherit' : 'none'">
      <button class="btn btn-primary" (click)="action('not')">Subtract / not <img class="bool-op-image" src="assets/operator_not.png"/></button>
    </span>
    </span>

    <span>
      <button [disabled]="getSelectedEntries().length == 0" class="btn btn-primary delete-queries"
              title="Select query or queries to delete"
              (click)="deleteQueries()">Delete <span class="fake-img"></span></button>
    </span>

    <span class="import-export-buttons" *ngIf="!deployConfigService.productionMode()">
      <span *ngIf="importingFromFile">
        <button class="btn btn-sm btn-primary" (click)="cancelImport()"
                title="Cancel query import">
          Cancel
        </button>
        Read from file: <input type="file" (change)="importFile($event)">
      </span>

      <span *ngIf="!importingFromFile">
        <button class="btn btn-primary" (click)="exportAll()" title="Export query history to a file">
          <span class="export-icon">
            <fa-icon [icon]="faDownload"></fa-icon>
          </span>Export history<span class="fake-img"></span>
        </button>
      </span>

      <span *ngIf="!importingFromFile">
        <button class="btn btn-primary" (click)="importQueries()"
          title="Import queries from file">
          <span class="import-icon">
            <fa-icon [icon]="faUpload"></fa-icon>
          </span>Import queries<span class="fake-img"></span>
        </button>
      </span>
    </span>

  <div class="select-all-none">
    Select: <a (click)="selectAll()">all</a> | <a (click)="selectNone()">none</a>
  </div>
  <table class="history-table">
    <thead>
      <tr>
        <th></th>
        <th class="results-header"><span (dblclick)="setAllCounts()">Results</span>
          <a [popover]="resultsHelpPopover"
             class="help-link"
             triggers="mouseenter:mouseleave" placement="bottom"
             container="body"><img src="/assets/info_icon.svg">
           <ng-content></ng-content>
         </a>
        </th>
        <th *ngIf="hasUpdatedCounts" class="results-header">
          Previous
          <a [popover]="previousHelpPopover"
             class="help-link"
             triggers="mouseenter:mouseleave" placement="bottom"
             container="body"><img src="/assets/info_icon.svg">
            <ng-content></ng-content>
          </a>
        </th>
        <th (click)="changeSortOrder()">Query history
          <img *ngIf="sortColumn != 'queryName'" title="Sort by query name" src="assets/sort_both.svg" />
          <img *ngIf="sortColumn == 'queryName'" src="assets/sort_up.svg" />
        </th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let histEntry of sortedHistoryEntries">
      <td>
        <label>
          <input [(ngModel)]="histEntry.checked" type="checkbox"/>
        </label>
      </td>
      <td [ngClass]="{'new-hist-item': histEntry.isNewEntry()}" class="result-count">
        <a (click)="queryClick(histEntry)">
          <span *ngIf="histEntry.getUpdatedCount() == undefined"
                title="Click to view results">
            {{histEntry.getResultCount()}}
          </span>
          <span *ngIf="histEntry.getUpdatedCount() != undefined"
                title="{{outOfDateCountTitle(histEntry)}}">
            {{histEntry.getUpdatedCount()}}
          </span>
        </a>
      </td>
      <td *ngIf="hasUpdatedCounts" class="result-count">
        <span *ngIf="histEntry.getUpdatedCount() != undefined"
              class="results-out-of-date">
          {{histEntry.getResultCount()}}
        </span>
      </td>
      <td [ngClass]="{'new-hist-item': histEntry.isNewEntry()}">
        <div class="edit-icon" *ngIf="!nameEditId() || nameEditId() != histEntry.getEntryId()">
          <span (click)="addOrEditName(histEntry)"
            title="{{histEntry.queryName()?'Edit query name':'Name this query'}}">
            <fa-icon [icon]="faEdit"></fa-icon>
          </span>
        </div>
        <div class="query-hist-name-and-button" *ngIf="(!nameEditId() || nameEditId() != histEntry.getEntryId()) && histEntry.queryName()">
          <span class="query-hist-name">{{removeTags(histEntry.queryName()!)}}</span>
          <span>
            <a *ngIf="showDetailsLink(histEntry)" (click)="showDetails(histEntry)" class="show-details-button">[details]</a>
            <span *ngIf="!showDetailsLink(histEntry)" class="disabled-show-details-button"
                  title="No more details to show">[details]</span>
          </span>
        </div>

        <div *ngIf="nameEditId() && nameEditId() == histEntry.getEntryId()" class="name-edit">
          Edit name:
          <input type="text" size="80" (keyup.enter)="storeNameEdit()"
                 [(ngModel)]="queryNameForEdit" placeholder="Query name ..." />
          <span class="save-cancel-name-edit">
          <button class="btn btn-sm" (click)="storeNameEdit()">Save</button>
          <button class="btn btn-sm" (click)="cancelNameEdit()">Cancel</button>
          </span>
        </div>
      </td>
      <td class="row-actions">
        <a *ngIf="histEntry.getResultCount() !== undefined" (click)="showInternalDetails(histEntry)">...</a>
      </td>
    </tr>
    </tbody>
  </table>
  </div>
</div>

<div *ngIf="sortedHistoryEntries.length == 0">
<span *ngIf="!deployConfigService.productionMode()">
  <button class="btn btn-primary" (click)="importQueries()"
          title="Import queries from a previous session">
    <span class="import-icon"><fa-icon [icon]="faUpload"></fa-icon></span>Import queries from file<span class="fake-img"></span>
  </button>
  <span *ngIf="importingFromFile">
    Read from file: <input type="file" (change)="importFile($event)">
  </span>
</span>
</div>

<ng-template #resultsHelpPopover>
  <div class="help-popover">
    <div>Number of matching genes</div>
    <div>Click on count to go to the gene list</div>
  </div>
</ng-template>

<ng-template #previousHelpPopover>
  <div class="help-popover">
    Number of matching genes from the previous run of this query, if different
  </div>
</ng-template>
