<fieldset class="annotation-table external-references">
  <legend>
    External references

    <app-help-link [route]="'/documentation/gene-page-external-references'"
                   [title]="'Click to view documentation for External references'">
    </app-help-link>
  </legend>

  <app-external-refs-table [geneDetails]="geneDetails">
  </app-external-refs-table>
</fieldset>
