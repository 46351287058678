<div class="versions">
  <div>

  <h4>Data source versions</h4>
  <div class="section-body">
    <ul>
      <li *ngFor="let nameVersion of dataSourceNameVersions">
        {{nameVersion[0]}}: {{nameVersion[1]}}
      </li>
    </ul>
  </div>
  </div>

  <div>
  <h4>CV versions</h4>
  <div class="section-body">
    <app-all-cv-versions></app-all-cv-versions>
  </div>
  </div>
</div>
