<span class="external-links">
  <span class="ext-links-title" *ngIf="linkConfigKeys.length > 0">External links:</span>
  <span *ngFor="let configKey of linkConfigKeys; let lastConf = last">
    <app-external-link [identifier]="termIdForLink"
                       [linkText]="configKey"
                       [linkConfigKey]="configKey">
    </app-external-link>
    <span class="link-sep" *ngIf="!lastConf">|</span>
  </span>
</span>
