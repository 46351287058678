<div *ngIf="termDetails">
  <div class="details-page-name-and-id">
    <span class="details-page-name-and-id-prefix">{{nameAndIdPrefix}}:</span>
    <span class="name-id-highlight">{{termDetails.name}}</span> ({{displayTermId}})
  </div>

  <div id="term-summary" class="details-page-summary">
    <table>
      <tr>
        <td *ngIf="termDetails.definition" class="details-label">Definition</td>
        <td *ngIf="filteredAncestors.length > 0" class="details-label">Parents</td>
      </tr>
      <tr *ngFor="let parentDetails of filteredAncestors; let firstRow = first">
        <td *ngIf="firstRow && termDetails.definition"
            [attr.rowspan]="filteredAncestors.length + 1">
          {{termDetails.definition}}
          <span *ngIf="defXrefs.length > 0">
[<span *ngFor="let xrefDetail of defXrefs ; let i = index"><a *ngIf="xrefDetail.refShort" title="Reference for this definition"
       routerLink="/reference/{{xrefDetail.identifier}}">{{xrefDetail.identifier}}</a><span
       title="Reference for this definition"
       *ngIf="!xrefDetail.refShort && !xrefDetail.url">{{xrefDetail.identifier}}</span><a
       *ngIf="!xrefDetail.refShort && xrefDetail.url"
       href="{{xrefDetail.url}}">{{xrefDetail.identifier}}</a><span
       *ngIf="i < defXrefs.length - 1">, </span></span>]
          </span>
        </td>
        <td class="term-parent-column">
          <span class="details-label term-parent-rel">{{parentDetails.relation_name}}</span>
          <a routerLink="/term/{{parentDetails.termid}}"
             routerLinkActive="active">{{parentDetails.term_name}}</a>
        </td>
      </tr>
      <tr *ngIf="filteredAncestors.length == 0">
        <td *ngIf="termDetails.definition">{{termDetails.definition}}</td>
      </tr>
    </table>
  </div>

  <div class="external-links">
    <ng-container *ngFor="let slimName of slimConfigNames">
      <div *ngIf="isInSubset(slimName)">
        This term is part of the
        <a (click)="goToFullSlim(slimName)">{{slimConfig[slimName].slim_display_name}}</a>

        <span class="esyn-link" *ngIf="slimName == 'bp_goslim_pombe'">
         <app-external-link [identifier]="termDetails.termid"
                            [linkText]="'View the esyN network'"
                            [linkConfigKey]="'esyn'"
                            [iconImage]="'esyn_icon.png'">
         </app-external-link>
        </span>
      </div>
    </ng-container>

    <div *ngIf="typeConfig">
      <app-term-external-links [typeConfig]="typeConfig" [termId]="termDetails.termid">
      </app-term-external-links>
    </div>
  </div>
</div>
