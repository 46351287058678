
<div *ngIf="!results && !err" class="query-running-spinner">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="results || err">
  <button class="btn btn-primary" title="Go to the Advanced Search interface"
    (click)="gotoAdvancedSearch()">&lt;- Advanced search</button>

  <div *ngIf="err" class="query-error">
     <div class="header">Error showing results:</div>
     {{err}}
  </div>
  <app-gene-results *ngIf="!err" [mode]="mode" [results]="results"></app-gene-results>
</div>
