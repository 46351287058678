<div id="term-single-gene-genotypes-view">
  <app-loading-spinner *ngIf="!termDetails"></app-loading-spinner>

  <div *ngIf="termDetails">
    <app-social-contact></app-social-contact>

    <app-term-page-summary [termDetails]="termDetails"></app-term-page-summary>

    <div class="term-actions">
      <span class="title">Actions: </span>
      <a routerLink="/query/save/from/term_subset/{{termDetails.termid}}/{{termDetails.name}}">
        Use these genes in query...
      </a>
    </div>

    <hr style="clear: both" />

    <div class="term-page-view-change">
      <a routerLink="/term/{{termDetails.termid}}"
         routerLinkActive="active">View all annotations for this term ...</a>
    </div>

    <div>
      <app-genes-table [description]="'genes from single locus genotypes annotated with &quot;' + termDetails.name + '&quot; (' + termDetails.termid + ') or its descendants'"
                       [genesOrResults]="singleLocusGenotypeGenes"></app-genes-table>
    </div>
  </div>
</div>
