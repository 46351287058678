<div class="query-node">
  <div class="node-buttons">
    <div [ngClass]="{'node-selected-button': !activeConf}"
         (click)="clearQuery()" class="node-button">
      <div class="non-heading-button button-level-0">
        New query
      </div>
    </div>
    <div [ngClass]="{'node-selected-button': activeConf && conf.id == activeConf.id}"
         class="node-button"
         *ngFor="let conf of nodeTypes">
      <div *ngIf="conf.nodeType === 'heading'" class="heading-button button-level-{{conf.level}}">
        <span>{{upperCaseIntial(conf.displayName)}}:</span>
      </div>
      <div *ngIf="conf.nodeType !== 'heading'"
           (click)="setNodeType(conf.id)"
           class="non-heading-button button-level-{{conf.level}}">
        <span>{{upperCaseIntial(conf.displayName)}}</span>
        <span class="indicator-filler"></span>
        <span *ngIf="conf.nodeType == 'sub-nodes'" class="sub-nodes-indicator">&#62;</span>
      </div>
    </div>
    <div class="button-filler">

    </div>
  </div>

  <div class="node-components">
    <div class="node-top" *ngIf="!activeConf">
      <div class="node-top-description">
        Choose a starting point from left-hand menu
      </div>
      <div class="node-top-help">
        <img src="/assets/info_icon.svg"> <a routerLink="/documentation/advanced-search">Advanced search documentation</a>
      </div>
      <div class="node-top-id-mapper">
        <span>For bulk lookup of UniProt accessions and ortholog IDs use the</span>
        <a routerLink="/identifier-mapper/">Identifier Mapper</a>
      </div>
    </div>

    <div *ngIf="activeConf">
      <app-query-node-display [nodeConfig]="activeConf"
                              (nodeEvent)="emitEvent($event)">
      </app-query-node-display>
    </div>
  </div>
</div>
