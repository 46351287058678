<ng-template #viaibilityHelpPopover>
  <div class="help-popover">
Details of individual viability phenotypes and conditions are in the "population phenotype" section below
  </div>
</ng-template>

<span class="deletion-viability-status">
  <span [className]="getViabilityClass()">
    <strong>Gene Deletion Viability:</strong> {{getDisplayViability()}}
    <a [popover]="viaibilityHelpPopover" class="help-link" triggers="mouseenter:mouseleave" placement="bottom" container="body">
      <img src="/assets/info_icon.svg">
    </a>
  </span>
</span>
