<div class="tool-container">
  <div id="query-build-heading" class="tool-heading-section">
    <a class="tool-heading-help-link" routerLink="/documentation/advanced-search">
      <img src="/assets/info_icon.svg"> Documentation
    </a>

    <h3 class="tool-heading-title">Advanced search</h3>
  </div>
  <div class="query-node-add tool-content">

    <app-query-node (nodeEvent)="nodeEvent($event)"
                    [startNodeType]="startNodeType"></app-query-node>
  </div>
  <div class="query-history">
    <app-query-history (gotoResults)="gotoResults($event)"></app-query-history>
  </div>
</div>
