<fieldset *ngIf="annotationTable && annotationTable.length > 0" class="annotation-table">
  <legend>
    Quantitative gene expression

    <app-help-link [route]="'/documentation/gene-page-gene-expression'"
                   [title]="'Click to view documentation for Quantitative gene expression'">
    </app-help-link>
  </legend>

  <div class="view-controls">
    <a *ngIf="!detailsMode()" (click)="setDetailsView()">
      Show details ...
    </a>
    <a *ngIf="detailsMode()" (click)="setSummaryView()">
      Show summary ...
    </a>
  </div>

  <div class="filter-controls">
    <app-annotation-table-filters *ngIf="filterConfig"
                                  [filterConfig]="filterConfig"
                                  [scope]="scope"
                                  [annotationTable]="annotationTable"
                                  [currentViewState]="currentViewState"
                                  (filterChange)="updateCurrentFilter($event)">
    </app-annotation-table-filters>
  </div>

  <div *ngIf="tableIsFiltered" class="filter-count">
    <span *ngIf="filteredAnnotationCount == annotationCount">
      Showing all annotations
    </span>
    <span *ngIf="filteredAnnotationCount != annotationCount">
      Showing {{filteredAnnotationCount}} of {{annotationCount}} annotations
    </span>
  </div>

  <table *ngIf="filteredTable.length != 0">
    <thead>
      <tr>
        <th>Description</th>
        <th *ngIf="showGene">Gene</th>
        <th>Molecules/cell (avg.)</th>
        <th>Extension</th>
        <th *ngIf="detailsMode()">Condition</th>
        <th *ngIf="detailsMode()">Scale</th>
        <th *ngIf="detailsMode()">Evidence</th>
        <th *ngIf="detailsMode() && showReference">Reference</th>
      </tr>
    </thead>
    <tbody *ngFor="let termAnnotation of filteredTable ; trackBy:trackByTermId">
      <tr class="term-annotation"
          *ngFor="let annotation of termAnnotation.annotations ; let i = index">
        <td><span  *ngIf="i==0">{{termAnnotation.term.name}}</span></td>
        <td *ngIf="showGene">
          <span *ngFor="let gene of annotation.genes">
            <app-gene-link class="app-link" [long]="false" [gene]="gene"></app-gene-link>
          </span>
        </td>
        <td *ngIf="annotation.gene_ex_props">{{annotation.gene_ex_props.avg_copies_per_cell}}</td>
        <td>
          {{annotation.qualifiers}}<app-extension-display
                                      *ngIf="annotation.extension"
                                      [extension]="annotation.extension"></app-extension-display>
        </td>
        <td *ngIf="detailsMode()">
          <div class="conditions">
            <div *ngFor="let condition of annotation.conditions ; let i = index">
              <app-term-link [term]="condition">
              {{condition.name}}</app-term-link><span *ngIf="i < annotation.conditions.length - 1">,</span>
            </div>
          </div>
        </td>
        <td *ngIf="detailsMode()">
          <span *ngIf="annotation.gene_ex_props">{{annotation.gene_ex_props.scale}}</span>
        </td>
        <td *ngIf="detailsMode()">
          <app-evidence-link class="app-link" *ngIf="annotation.evidence" [evidence]="annotation.evidence"></app-evidence-link>
        </td>
        <td *ngIf="detailsMode() && showReference">
          <app-reference-short *ngIf="annotation.reference"
                               [reference]="annotation.reference"></app-reference-short>
        </td>
      </tr>
    </tbody>
  </table>

  <span class="no-annotations" *ngIf="filteredTable.length == 0">
    (No annotations after filtering)
  </span>


  <div class="violin-plot" *ngIf="geneDetails">
    <a *ngIf="!plotVisible" (click)="showPlot()">
      <img alt="Violin plot icon" src="/assets/violin_plot_icon.png"/>

      View gene expression plots

    </a>
    <div *ngIf="plotVisible">
      <span *ngIf="!plotLoaded">
        Loading ...
      </span>
      <span *ngIf="plotLoaded">
        "Violin plots" of available gene expression datasets
        <span *ngIf="geneDetails">
          with the expression of {{geneDetails.displayName}} (if
          available) shown as a red point:
        </span>
      </span>
      <img (load)="plotLoaded=true" class="violin-plot-image"
           alt="Violin plot for {{geneDetails.name || geneDetails.uniquename}}"
           src="/api/v1/dataset/latest/gene_ex_violin_plot/small/{{geneDetails.uniquename}}"/>
    </div>
  </div>
</fieldset>
