<div id="identifier-mapper" class="tool-container">
  <div id="id-heading-container" class="tool-heading-section">
    <a class="tool-heading-help-link" routerLink="/documentation/id-mapper">
      <img src="/assets/info_icon.svg"> Documentation
    </a>

    <h3 class="tool-heading-title">
      Identifier mapping tool
    </h3>
  </div>

  <div class="id-mapper-main tool-content">
    <div class="help-message">
      Select an identifier type, then provide identifiers to map to
      {{organismCommonName}} genes. Upload a file, or type or paste
      identifiers into the box, separated by commas, spaces, tabs or
      one per line.
    </div>

    <div class="id-mapper-query">
      <div class="id-select">
        Identifier type: <select [compareWith]="mapperTypeCompare"
                                 [(ngModel)]="identifierMapperService.mapperType">
          <option *ngFor="let mapperType of identifierMapperService.mapperTypes()"
                  [selected]="mapperType.id == 'uniprot'"
                  [ngValue]="mapperType">{{mapperType.displayNameWithExamples}}</option>
        </select>
      </div>

      <textarea class="id-input" cols="50" rows="8"
                (ngModelChange)="inputTextChanged($event)"
                [(ngModel)]="identifierMapperService.inputText"></textarea>

      <div class="id-actions">
        <button class="id-lookup-action btn btn-primary"
                [disabled]="filteredIds().length == 0"
                (click)="lookup()">Lookup</button>

        <button class="id-lookup-action btn btn-warning"
                [disabled]="filteredIds().length == 0"
                (click)="clear()">Clear</button>

        <span class="id-read-from-file">
          Read from file: <input type="file" (change)="readFile($event)">
        </span>
      </div>
    </div>
  </div>
</div>
