<fieldset *ngIf="annotationTable && annotationTable.length > 0" class="annotation-table">
  <legend>
    {{annotationTypeDisplayName}}

    <app-help-link [route]="'/documentation/orthologs'" [title]="'Click to view documentation for Paralogs'">
    </app-help-link>
  </legend>

  <div class="paralog-table annotation-table-container">
  <table>
    <thead>
      <tr>
        <th>Paralogous gene</th>
        <th>Product</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let annotation of annotationTable">
        <td>
          <span class="paralog-gene">
            <app-gene-link [gene]="annotation.paralog"></app-gene-link>
          </span>
        </td>
        <td>
          <span>{{annotation.paralog.product || ''}}</span>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</fieldset>
