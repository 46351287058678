<ng-template #searchBoxTemplate let-model="item" let-index="index">
  <div class="search-box-contents">
  <span *ngIf="model.name"><strong>{{model.name}}</strong> ({{model.uniquename}})</span>
  <span *ngIf="!model.name"><strong>{{model.uniquename}}</strong></span>
    <div *ngIf="model.otherDetails" class="match-extra">
      <div *ngFor="let detail of model.otherDetails">
        <span class="completion-match-detail" [innerHTML]="detail"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchHelpPopover>
  <div class="help-popover">
    <ul>
      <li>Start typing to search for genes</li>
      <li>For publications: type or paste a PubMed ID and press enter</li>
      <li>For ontology terms: type or paste the term ID and press enter</li>
      <li>Or see the other options under the "Search" menu</li>
    </ul>
  </div>
</ng-template>

<span *ngIf="searchSummaries.length == 0 && !geneSummariesFailed" class="data-loading-message">Loading ...</span>
<span *ngIf="searchSummaries.length == 0 && geneSummariesFailed" class="data-loading-message data-loading-error">
  Search box initialisation failed - try reloading
</span>

<input #searchBox
       [hidden]="searchSummaries.length == 0"
       placeholder="search ..."
       [(ngModel)]="fieldValue"
       (keyup.enter)="enterPressed()"
       [typeahead]="dataSource"
       [typeaheadAsync]="true"
       [typeaheadMinLength]="2"
       [typeaheadItemTemplate]="searchBoxTemplate"
       [typeaheadSingleWords]="false"
       typeaheadGroupField="matchType"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       (typeaheadNoResults)="typeaheadNoResults($event)"
       [typeaheadOptionField]="'name'"
       [typeaheadOptionsInScrollableView]="10"
       [typeaheadOptionsLimit]="20"
       class="form-control search-box">

<span *ngIf="isInitialised()" class="search-help">
    <img [popover]="searchHelpPopover"
         triggers="mouseenter:mouseleave" placement="right"
         container="body"
         src="/assets/info_icon.svg">
</span>

<span class="search-info" [style.visibility]="noMatches() ? 'visible' : 'hidden'">
  No matches
</span>
