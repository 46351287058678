<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add QuiLT column</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="!selectedTerm" class="modal-body">
    Select a term from one of these ontologies to add to QuiLT:
    <div class="cv-name-list">
      <div *ngFor="let cvDisplayName of visCvDisplayNames">
        {{cvDisplayName}}
      </div>
    </div>
    <app-term-name-complete
      [cvName]="cvNamesForTermComplete" [placeholder]="'starting typing to choose a term ...'"
      (termMatched)="termMatched($event)">
    </app-term-name-complete>
  </div>
  <div *ngIf="selectedTerm" class="modal-body">
    <div class="found-header">
      Selected term:
    </div>
    <div class="found-term">
      <span class="found-termid">{{selectedTerm.termid}}</span> {{selectedTerm.name}}
    </div>
    <div class="selection-clear">
      <a (click)="clearSelection()" title="Find a different term">[clear]</a>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!isValid()"
            title="{{applyButtonTitle()}}" (click)="apply()">Apply</button>
  </div>
</div>
