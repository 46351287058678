<fieldset class="annotation-table">
  <legend>
    Transcript details
  </legend>

  <div class="annotation-table-container">
    <div *ngFor="let transcript of displayTranscripts; let idx = index">

      <div *ngIf="idx == 0" id="toggles-and-key">
        <div title="Check the box to show a table of exon/intron/UTR positions">
          <input [(ngModel)]="showCoordTable" id="show-coord-table" type="checkbox"/>
          <label for="show-coord-table">Show exon/intron/UTR positions</label>
        </div>
        <div *ngIf="showCoordTable"
             title="Check the box to show positions with respect to the start of the transcript">
          <input [(ngModel)]="showTranscriptCoords" id="show-transcript-coords" type="checkbox" />
          <label for="show-transcript-coords">Show transcript positions</label>
        </div>

        <div id="key">
          <div>Key:</div>
          <div class="key-box key-box-utr">&nbsp;</div>
          <div>UTR</div>
          <div class="key-box key-box-exon">&nbsp;</div>
          <div>exon</div>
        </div>
      </div>

      <div class="transcript-view-title">
        <div class="transcript-view-type-and-id">
          {{transcript.type()}} {{transcript.uniquename()}}
        </div>
        <div class="transcript-view-product">
          {{transcript.product()}}
        </div>
      </div>
      <div class="transcript-name-and-loc">
        Location: {{transcript.displayLocation()}},
        <span style="white-space: nowrap">chromosome {{chromosomeDisplayName}} {{geneStrand()}} strand</span>
        &nbsp;
        &nbsp;
        Product size: {{transcript.productSizeString()}}
      </div>

      <div class="part-container">
        <div class="transcript-graphic">
          <div *ngIf="transcript.padding()" class="padding"
            [style.width]="transcript.padding()!.getDivVW() + 'vw'">
          </div>
          <div *ngFor="let part of transcript.displayParts(); trackBy: partTrackBy"
               class="{{part.type()}}"
            [popover]="'' + popoverContent(part)" triggers="mouseenter:mouseleave" container="body"
            [class.highlight-part]="part.partId() == transcript.getHighlightedPart()"
            (mouseenter)="transcript.setHighlightedPart(part.partId())" [style.width]="part.getDivVW() + 'vw'">
            &nbsp;
          </div>
          <div class="arrow"></div>
        </div>
        <div *ngIf="showCoordTable" class="coords">
          <table>
            <tr *ngFor="let part of transcript.nonIntronParts(); index as idx; trackBy: partTrackBy">
              <td [class.highlight-part]="part.partId() == transcript.getHighlightedPart()"
                  (mouseenter)="transcript.setHighlightedPart(part.partId())"
                  [popover]="'' + popoverContent(part)"
                  triggers="mouseenter:mouseleave" container="body" placement="bottom"
                  class="part-name">
                {{part.displayName()}}
              </td>
              <td [class.highlight-part]="part.partId() == transcript.getHighlightedPart()"
                  (mouseenter)="transcript.setHighlightedPart(part.partId())"
                  [popover]="'' + popoverContent(part)"
                  triggers="mouseenter:mouseleave" container="body" placement="bottom"
                  class="part-location">
                {{part.displayLocation(showTranscriptCoords)}}
              </td>
              <td *ngIf="part.nextPart() && part.nextPart().type().includes('intron')"
                  [class.highlight-part]="part.nextPart().partId() == transcript.getHighlightedPart()"
                  (mouseenter)="transcript.setHighlightedPart(part.nextPart().partId())"
                  [popover]="'' + popoverContent(part)"
                  triggers="mouseenter:mouseleave" container="body" placement="bottom"
                  class="part-name-column-3">
                {{part.nextPart().displayName()}}
              </td>
              <td *ngIf="part.nextPart() && part.nextPart().type().includes('intron')"
                  [class.highlight-part]="part.nextPart().partId() == transcript.getHighlightedPart()"
                  (mouseenter)="transcript.setHighlightedPart(part.nextPart().partId())"
                  [popover]="'' + popoverContent(part)"
                  triggers="mouseenter:mouseleave" container="body" placement="bottom"
                  class="part-location">
                {{part.nextPart().displayLocation(showTranscriptCoords)}}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</fieldset>
