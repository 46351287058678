<div class="widgets">
  <div class="widgets-picker-container">
  <div class="widgets-picker"
       [ngClass]="{'widget-buttons-horizontal': currentWidget() == 'none', 'widget-buttons-vertical': currentWidget() != 'none'}">
    <span *ngIf="currentWidget() == 'none'" class="widgets-available">
      Available widgets:
    </span>
    <ng-container *ngIf="hasStructure()">
    <div *ngIf="currentWidget() != 'alphafold_viewer'"
         class="non-active-widget-button"
         title="Click to view predicted protein structure from AlphaFold">
      <a (click)="setWidget('alphafold_viewer')">
        <div>Predicted structure</div>
        <img class="widget-button" src="assets/structure-widget-icon.png" />
      </a>
    </div>
    <div *ngIf="currentWidget() == 'alphafold_viewer'" class="active-widget-button">
      <div>Predicted structure</div>
      <img class="widget-button" src="assets/structure-widget-icon.png" />
    </div>
    </ng-container>

    <ng-container *ngIf="hasPDBStructure()">
      <div *ngIf="currentWidget() != 'pdb_viewer'" class="non-active-widget-button"
        title="Click to view protein structures from PDB">
        <a (click)="setWidget('pdb_viewer')">
          <div>PDB structures</div>
          <img class="widget-button" src="assets/pdb-structure-widget-icon.png" />
        </a>
      </div>
      <div *ngIf="currentWidget() == 'pdb_viewer'" class="active-widget-button">
        <div>PDB structures</div>
        <img class="widget-button" src="assets/pdb-structure-widget-icon.png" />
      </div>
    </ng-container>

    <ng-container *ngIf="hasRna2dStructure()">
      <div *ngIf="currentWidget() != 'rna_2d_structure'" class="non-active-widget-button" title="Click to view RNA 2D structure">
        <a (click)="setWidget('rna_2d_structure')">
          <div>RNA 2D structure</div>
          <img class="widget-button" src="assets/rna-2d-structure-widget-icon.png" />
        </a>
      </div>
      <div *ngIf="currentWidget() == 'rna_2d_structure'" class="active-widget-button">
        <div>RNA 2D structure</div>
        <img class="widget-button" src="assets/rna-2d-structure-widget-icon.png" />
      </div>
    </ng-container>

    <ng-container *ngIf="showGoCams()">
      <div *ngIf="currentWidget() != 'gocam_viewer'" class="non-active-widget-button"
           title="Click to view GO-CAM models">
        <a (click)="setWidget('gocam_viewer')">
          <div>GO-CAM models</div>
          <img class="widget-button" src="assets/go-cam-widget-icon.png" />
        </a>
      </div>
      <div *ngIf="currentWidget() == 'gocam_viewer'" class="active-widget-button">
        <div>GO-CAM models</div>
        <img class="widget-button" src="assets/go-cam-widget-icon.png" />
      </div>
    </ng-container>

    <ng-container *ngIf="showProteinFeatures()">
    <div *ngIf="currentWidget() != 'protein_feature_viewer'" class="non-active-widget-button"
         title="Click to view protein features">
      <a (click)="setWidget('protein_feature_viewer')">
        <div>Protein features</div>
        <img class="widget-button" src="assets/protein-feature-widget-icon.png" />
      </a>
    </div>
    <div *ngIf="currentWidget() == 'protein_feature_viewer'" class="active-widget-button">
      <div>Protein features</div>
      <img class="widget-button" src="assets/protein-feature-widget-icon.png" />
    </div>
    </ng-container>

    <div *ngIf="currentWidget() != 'genome_browser'"
         class="non-active-widget-button"
         title="Click to view gene in genome browser">
      <a (click)="setWidget('genome_browser')">
        <div>Genome browser</div>
        <img class="widget-button" src="assets/jbrowse-widget-icon.png"/>
      </a>
    </div>
    <div *ngIf="currentWidget() == 'genome_browser'" class="active-widget-button">
      <div>Genome browser</div>
      <img class="widget-button" src="assets/jbrowse-widget-icon.png" />
    </div>

    <div *ngIf="currentWidget() != 'none'"
         class="non-active-widget-button widget-button-hide-all"
         title="Click to hide all widgets">
      <a (click)="hideAllWidgets()">
        Hide all
      </a>
    </div>
    <div *ngIf="currentWidget() == 'none'" class="active-widget-button widget-button-hide-all">
      Hide all
    </div>

  </div>
  </div>

  <div *ngIf="currentWidget() != 'none'" class="widgets-container">

<div *ngIf="currentWidget() == 'genome_browser'" class="chromosome-region-view">

  <div *ngIf="getJBrowseIFrameURL()" class="jbrowse-container">
    <iframe [src]="getJBrowseIFrameURL()! | safeUrl" scrolling="no" class="embedded-jbrowse">
    </iframe>
  </div>

  <app-gene-neighbourhood [focusGeneUniquename]="geneDetails.uniquename"
                          [focusGeneName]="geneDetails.name"
                          [neighbourhood]="geneDetails.gene_neighbourhood"></app-gene-neighbourhood>
</div>

<div *ngIf="currentWidget() == 'alphafold_viewer'" class="alphafold-view">
  <app-alphafold-viewer [geneDetails]="geneDetails">
  </app-alphafold-viewer>
</div>

<div *ngIf="currentWidget() == 'pdb_viewer'" class="pdb-structure-view">
  <app-pdb-structure-viewer
      [displayName]="geneDetails.displayName" [pageType]="'gene'"
      [pdbEntries]="geneDetails.pdb_entries">
  </app-pdb-structure-viewer>
</div>

<div *ngIf="currentWidget() == 'protein_feature_viewer'" class="protein-feature-view">
  <app-protein-feature-viewer [geneDetails]="geneDetails">
  </app-protein-feature-viewer>
</div>

<div *ngIf="currentWidget() == 'gocam_viewer'" class="go-cam-view">
  <app-go-cam-viewer [geneOrTermDetails]="geneDetails">
  </app-go-cam-viewer>
</div>

<div *ngIf="currentWidget() == 'rna_2d_structure'" class="rna-2d-sttructure-viewier">
  <app-rna-structure [geneDetails]="geneDetails"></app-rna-structure>
</div>

</div>
</div>
