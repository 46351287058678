<span [class.diploid-genotype-name]="isDiploid()" [class.short-genotype-link]="isShortDisplayName">
  <ng-template #allelesPopover>
    <div *ngIf="isDiploid()">Diploid genotype</div>
    <div *ngIf="background">Background: <span class="genotype-background">{{background}}</span></div>
    <app-genotype-allele-summary class="link-allele-summary" [genotype]="genotype">
    </app-genotype-allele-summary>

    <div class="genotype-extra-help">
      (Click to view all annotation for this genotype)
    </div>
  </ng-template>
  <a [popover]="allelesPopover"
     triggers="mouseenter:mouseleave"
     placement="bottom"
     container="body"
     routerLink="/genotype/{{genotype.display_uniquename}}"
     routerLinkActive="active">
    <span [innerHTML]="displayName"></span>
  </a>
</span>
