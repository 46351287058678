<ng-template #termPopover>
  <div class="term-popover">
    <div *ngIf="termSummary && cvDisplayName" class="term-cv"><span>{{cvDisplayName}}</span></div>

    <div>
      <span *ngIf="term.name" class="term-name">{{term.name}}</span>
      <span class="term-id">({{getDisplayTermId()}})</span>
    </div>
    <div *ngIf="termSummary" class="term-definition">{{termSummary.definition}}</div>

    <div *ngIf="hasReactions" class="term-reactions">
      <app-reaction-view [termIdRefs]="termSummary!" [miniView]="true"></app-reaction-view>
    </div>

    <div *ngIf="gocamCount == 1" class="term-gocams">
      A GO-CAM pathway model is available for this process
    </div>

    <div *ngIf="gocamCount > 1" class="term-gocams">
      GO-CAM pathway models are available for this process
    </div>

    <div class="link-hint">({{getLinkHint()}})</div>
  </div>
</ng-template>

<a #link="bs-popover"
   *ngIf="!externalLink"
   routerLink="/term/{{term.termid}}"
   [popover]="termPopover"
   (onShown)="shown()"
   triggers=""
   (mouseenter)="mouseEnter()"
   (mouseleave)="mouseLeave()"
   placement="bottom" container="body">
  <span class="term-link"><ng-content></ng-content></span>
</a>

<a #link="bs-popover"
   *ngIf="externalLink"
   href="{{externalLink}}"
   [popover]="termPopover"
   (onShown)="shown()"
   triggers=""
   (mouseenter)="mouseEnter()"
   (mouseleave)="mouseLeave()"
   placement="bottom" container="body">
  <span class="term-link">{{term.name}}</span>
</a>
