<div *ngIf="geneSummaries" id="motif-search-container" class="tool-container">
  <div id="motif-search-heading-section" class="tool-heading-section">
    <app-help-link class="tool-heading-help-link"  [route]="'/documentation/motif-search'">
      Documentation
    </app-help-link>

    <h3 class="tool-heading-title">Search for a motif <span *ngIf="organismCommonName">in all {{organismCommonName}} protein sequences</span></h3>
  </div>

  <div class="input-and-doc-link">
  <div class="input-area">
    <input type="text" [(ngModel)]="motif" placeholder="Enter a motif ..." (ngModelChange)="motifChange($event)"/>
    <a class="input-reset" (click)="resetSearch()">clear</a>
  </div>

  <div class="motif-docs-link">
    <app-help-link [route]="'/documentation/motif-search'">
      Motif search documentation
    </app-help-link>
  </div>
  </div>

  <div class="help-while-typing" *ngIf="searchState != SearchState.ShowHelp">
    <app-motif-search-help [full]="false"></app-motif-search-help>
  </div>

  <div class="help-when-not-typing" *ngIf="searchState == SearchState.ShowHelp">
    <app-motif-search-help [full]="true"></app-motif-search-help>
  </div>

  <div>
    <div class="no-match" *ngIf="searchState == SearchState.NoResults">
      <h4>No matching peptides</h4>
    </div>

    <div class="searching-message" *ngIf="searchState == SearchState.Searching">
			<h4>Searching ...</h4>
    </div>

    <div class="results" *ngIf="searchState == SearchState.SomeResults">
      <div class="result-header">

      <div *ngIf="peptideResults.length == 1" class="match-count">
        One matching peptide
      </div>

      <div *ngIf="peptideResults.length == peptideResultsWithDetails.length && peptideResults.length != 1" class="match-count">
        Showing all <span class="count">{{peptideResults.length}}</span> matching peptides
      </div>

      <div class="match-count" *ngIf="peptideResults.length > peptideResultsWithDetails.length">
        Showing the first <span class="count">{{defaultGenesWithDetails}}</span>
        of <span class="count">{{peptideResults.length}}</span> matching peptides
        <a class="show-all-link" (click)="showAll()">Show all</a>
      </div>

      <div class="send-genes">
        <a (click)="sendToQueryBuilder()">Send gene
          <span *ngIf="peptideResults.length > 1">list</span> to advanced search</a>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <td>Gene</td>
          <td class="product">Product</td>
          <td class="start-pos">Start</td>
          <td colspan="3"></td>
          <td class="end-pos">End</td>
        </tr>
      </thead>
      <tbody *ngFor="let peptideResult of peptideResultsWithDetails">
        <tr class="match-line" *ngFor="let peptideMatch of peptideResult.matches ; let i = index">
          <td class="peptide-id" *ngIf="i==0" [attr.rowspan]="peptideResult.matches.length">
            <app-gene-link [gene]="geneSummaries[geneIdOf(peptideResult)]" [long]="false"></app-gene-link>
          </td>
          <td class="product" *ngIf="i==0" [attr.rowspan]="peptideResult.matches.length">{{geneSummaries[geneIdOf(peptideResult)].product || ''}}</td>
          <td class="start-pos">{{peptideMatch.start}}</td>
          <td class="before-peptides">{{peptideMatch.before}}</td>
          <td class="matching-peptides">{{peptideMatch.match}}</td>
          <td class="after-peptides">{{peptideMatch.after}}</td>
          <td class="end-pos">{{peptideMatch.end}}</td>
        </tr>
        <tr *ngIf="peptideResult.match_count > peptideResult.matches.length">
          <td></td><td></td><td colspan="5">Showing first {{peptideResult.matches.length}} matches.  <a (click)="morePeptideMatches(peptideResult.peptide_id)">Show all ...</a></td>
        </tr>
        <tr *ngIf="peptideResult.match_count == peptideResult.matches.length && peptideResult.match_count > 20">
          <td></td><td></td><td colspan="5">Showing all {{peptideResult.matches.length}} matches</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
