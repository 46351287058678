<fieldset *ngIf="references && references.length > 0" class="annotation-table references">
  <legend>
    Literature

    <app-help-link [route]="'/documentation/gene-page-literature'"
                   [title]="'Click to view documentation for the Literature section'">
    </app-help-link>
  </legend>

  <div *ngIf="pubmedSearchUrl" class="pubmed-search">
    <a href="{{pubmedSearchUrl}}">Search PubMed for {{pubmedSearchGeneLabel}}</a>
  </div>
  <div class="sort-buttons">
    Sort by:
    <span *ngIf="orderByField == '+gene_count'"
          title="Currently sorted by publication gene count">Publication gene count</span>
    <a *ngIf="orderByField != '+gene_count'" (click)="setOrderBy('+gene_count')"
       title="Click to sort list by publication gene count">Publication gene count</a> |
    <span *ngIf="orderByField == '+authors_abbrev'"
          title="Currently sorted alphabetically by first author">Authors</span>
    <a *ngIf="orderByField != '+authors_abbrev'" (click)="setOrderBy('+authors_abbrev')"
       title="Click to sort list alphabetically by first author">Authors</a> |
    Year:
    <span *ngIf="orderByField == '+publication_year'"
          title="Currently sorted by publication year (oldest first)">Ascending</span>
    <a *ngIf="orderByField != '+publication_year'" (click)="setOrderBy('+publication_year')"
       title="Click to sort list by publication year (oldest first)">Ascending</a> /
    <span *ngIf="orderByField == '-publication_year'"
          title="Currently sorted by publication year (most recent first)">Descending</span>
    <a *ngIf="orderByField != '-publication_year'" (click)="setOrderBy('-publication_year')"
       title="Click to sort list by publication year (most recent first)">Descending</a>
  </div>
  <div class="annotation-table-container">
  <table>
    <thead>
      <tr>
        <th>Details</th>
        <th *ngIf="orderByField == '+publication_year' || orderByField == '-publication_year'" title="Publication year">Year</th>
        <th title="Publication gene count">Genes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let reference of references | referenceOrderBy: orderByField">
        <td>
          <div class="pub-title">
            {{reference.title}}
          </div>
          <div>
            <span class="pub-authors">
              {{reference.authors_abbrev}}
            </span>
            <span class="pub-citation" *ngIf="!reference.journal">
              <span>
                {{reference.citation}}
              </span>
            </span>
            <span class="pub-citation" *ngIf="reference.journal">
              <span class="pub-journal">
                {{reference.journal}}
              </span>
              <span class="pub-citation-date-pages">
                {{reference.citation_date_pages}}
              </span>
            </span>
            <span class="pub-uniquename">{{reference.uniquename}}</span>
            <span class="curated-icon" *ngIf="isCurated(reference)">
              <img src="/assets/canto_icon.png" /> Curated
            </span>
            <span class="details-link">
            <app-reference-short [reference]="reference" [linkText]="'details ...'">
            </app-reference-short>
            </span>
          </div>
        </td>
        <td *ngIf="orderByField == '+publication_year' || orderByField == '-publication_year'">
          {{reference.publication_year}}
        </td>
        <td>
          <a title="Click to view the list of genes from this publication"
             routerLink="/ref_genes/{{reference.uniquename}}">{{reference.gene_count}}</a>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</fieldset>
