<div id="term-gene-view">
  <app-loading-spinner *ngIf="!termDetails && !apiError"></app-loading-spinner>
  <app-api-error-display *ngIf="apiError" [error]="apiError"></app-api-error-display>

  <div *ngIf="termDetails && !apiError">
    <app-social-contact></app-social-contact>

    <app-term-page-summary [termDetails]="termDetails"></app-term-page-summary>

    <div class="no-annotations" *ngIf="genes.length == 0">
      No annotations for {{termDetails.termid}}
    </div>
    <div *ngIf="genes.length > 0">
    <div class="term-actions">
      <span class="title">Actions: </span>
      <a routerLink="{{queryBuilderRouterLink}}">
        Use these genes in query...
     </a>
    </div>

    <hr style="clear: both" />

    <div *ngIf="showAllAnnotationsLink" class="term-page-view-change">
      <a routerLink="/term/{{termDetails.termid}}"
         routerLinkActive="active">View all annotations for this term ...</a>
    </div>
    </div>

    <div>
      <app-genes-table [description]="'genes annotated with &quot;' + termDetails.name + '&quot; (' + termDetails.termid + ') or its descendants'"
                       [genesOrResults]="genes"></app-genes-table>
    </div>
  </div>
</div>
