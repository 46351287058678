<table class="genotype-allele-summary" [ngClass]="{'diploid': isDiploid}">
  <thead>
    <th *ngIf="!isDiploid">Gene</th>
    <th *ngIf="isDiploid">Locus</th>
    <th *ngIf="showProduct">Product</th>
    <th>Allele name</th>
    <th *ngIf="hasSynonyms">
      Synonyms
    </th>
    <th>Description</th>
    <th *ngIf="hasComment">
      Comment
    </th>
    <th>Type</th>
    <th>Expression</th>
  </thead>
  <tbody *ngFor="let locus of genotype.loci">
    <tr *ngFor="let expressedAllele of locus.expressed_alleles ; let i = index">
      <td *ngIf="i == 0" [attr.rowspan]="isDiploid ? 2 : 1">
        <app-gene-link *ngIf="expressedAllele.allele.gene" class="app-link"
                       [long]="false" [gene]="expressedAllele.allele.gene"></app-gene-link>
      </td>
      <td *ngIf="showProduct && i == 0" [attr.rowspan]="isDiploid ? 2 : 1">
        {{expressedAllele.allele.gene?.product}}
      </td>
      <td class="allele-name">
        <span [innerHTML]="tidyAlleleName(expressedAllele.allele.name)"></span>
      </td>
      <td *ngIf="hasSynonyms">
        <ul>
          <li *ngFor="let synonymDetail of expressedAllele.allele.synonyms">
            <span *ngIf="!synonymDetail.reference && !synonymDetail.reference_unquename">
              {{synonymDetail.name}}
            </span>
            <span *ngIf="!synonymDetail.reference && synonymDetail.reference_unquename"
                  [title]="synonymDetail.reference_unquename">
              {{synonymDetail.name}}
            </span>
            <app-reference-short *ngIf="synonymDetail.reference"
                                 [reference]="synonymDetail.reference"
                                 [popupHeader]="getPopupHeaderText(synonymDetail.reference, 'synonym') + ':'"
                                 [linkText]="synonymDetail.name">
            </app-reference-short>
          </li>
        </ul>
      </td>
      <td>
        <span [innerHTML]="alleleDisplayDescription(expressedAllele.allele)"></span>
      </td>
      <td *ngIf="hasComment">
        <ul>
          <li *ngFor="let comment of expressedAllele.allele.comments">
            <span *ngIf="comment.reference == undefined && !comment.reference_unquename">
              {{comment.comment}}
            </span>
            <span *ngIf="comment.reference == undefined && comment.reference_unquename"
                  [title]="comment.reference_unquename">
              {{comment.comment}}
            </span>
            <app-reference-short *ngIf="comment.reference != undefined"
                                 [reference]="comment.reference"
                                 [popupHeader]="getPopupHeaderText(comment.reference, 'comment') + ':'"
                                 [linkText]="comment.comment">
            </app-reference-short>
          </li>
        </ul>
      </td>
      <td>{{expressedAllele.allele.allele_type}}</td>
      <td>{{expressedAllele.expression}}</td>
    </tr>
  </tbody>
</table>
