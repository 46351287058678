<div class="pombase-social-contacts">

<span class="social">
  <a *ngIf="communityMailingListConfig" href="{{communityMailingListConfig.url}}">
    <img title="{{communityMailingListConfig.title}}" src="assets/{{communityMailingListConfig.icon}}"/>
  </a>
  <a *ngIf="socialMediaLinks.slack" href="{{socialMediaLinks.slack}}"><img title="{{siteName}} on Slack" src="assets/slack_icon.png"/></a>
  <a *ngIf="socialMediaLinks.bluesky" href="https://bsky.app/profile/{{socialMediaLinks.bluesky}}"><img title="@{{socialMediaLinks.bluesky}} on Twitter" src="assets/bluesky_icon.png"/></a>
  <a *ngIf="socialMediaLinks.twitter" href="https://twitter.com/{{socialMediaLinks.twitter}}"><img title="@{{socialMediaLinks.twitter}} on Twitter" src="assets/twitter_icon.png"/></a>
  <a *ngIf="socialMediaLinks.mastodon" href="{{socialMediaLinks.mastodon}}"><img title="{{siteName}} on Mastodon" src="assets/mastodon_icon.svg"/></a>
  <a *ngIf="socialMediaLinks.facebook" href="https://www.facebook.com/{{socialMediaLinks.facebook}}"><img title="{{siteName}} on Facebook" src="assets/facebook_icon.png"/></a>
  <a *ngIf="socialMediaLinks.github" href="https://github.com/{{socialMediaLinks.github}}"><img title="{{siteName}} on GitHub" src="assets/github_icon.png"/></a>
  <a *ngIf="socialMediaLinks.linkedin" href="{{socialMediaLinks.linkedin}}"><img title="{{siteName}} on LinkedIn" src="assets/linkedin_icon.png"/></a>
</span>

<app-contact-email class="contact-email" [linkName]="'Contact curators ...'"
                   [subject]="subject || 'A comment on ' + siteName"></app-contact-email>

</div>
