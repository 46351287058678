<div>
  <app-loading-spinner *ngIf="genotypes.length == 0"></app-loading-spinner>

  <fieldset *ngIf="genotypes.length > 0" class="genotypes-table">
    <legend>
      <span>{{legend}}</span>
    </legend>

    <table>
      <thead>
        <tr>
          <th>
            <a *ngIf="orderByField != 'allele'" (click)="setOrderBy('allele')"
               title="Click to sort">Allele</a>
            <span *ngIf="orderByField == 'allele'">Allele</span>
          </th>
          <th>
            <a *ngIf="orderByField != 'gene'" (click)="setOrderBy('gene')"
               title="Click to sort">Gene</a>
            <span *ngIf="orderByField == 'gene'">Gene</span>
          </th>
          <th>
            <a *ngIf="orderByField != 'product'" (click)="setOrderBy('product')"
               title="Click to sort">Product</a>
            <span *ngIf="orderByField == 'product'">Product</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let genotype of genotypes | genotypeOrderBy: orderByField">
          <td><app-genotype-link class="app-link" [genotype]="genotype"></app-genotype-link></td>
          <td>
            <app-gene-link class="app-link" [gene]="genotype.loci[0].expressed_alleles[0].allele.gene"></app-gene-link>
          </td>
          <td>{{genotype.loci[0].expressed_alleles[0].allele.gene.product}}</td>
        </tr>
      </tbody>
    </table>
  </fieldset>
</div>
