<div *ngIf="nodeConfig">
  <div class="node-top-description" *ngIf="nodeConfig.topDescription">
    <span *ngFor="let part of nodeConfig.topDescription"><span *ngIf="part.text">{{part.text}}</span><a *ngIf="part.link" routerLink="{{part.link.route}}">{{part.link.link_text}}</a></span>:
  </div>

  <div *ngIf="nodeConfig.nodeType == 'ontology'">
    <app-query-term-node [termNodeConfig]="nodeConfig"
                         [placeholder]="nodeConfig.placeholder ? nodeConfig.placeholder :'Start typing a ' + nodeConfig.displayName + ' term name or ID ...'"
                         (newTermNode)="newTermNode($event)"></app-query-term-node>
  </div>

  <div *ngIf="nodeConfig.id == 'gene_list'">
    <app-gene-list-lookup [lookupFieldType]="'id-and-name'" (genesFound)="genesFound($event)"></app-gene-list-lookup>
  </div>

  <div *ngIf="nodeConfig.id == 'uniprot_id_list'">
    <span>For bulk lookup of UniProt accessions use the</span>
    <a routerLink="/identifier-mapper/">Identifier Mapper</a>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'has-ortholog'">
    <select [(ngModel)]="selectedOrthologOrganism"
            (ngModelChange)="selectedOrthologChange()">
      <option [ngValue]="undefined">Pick a species ...</option>
      <option *ngFor="let org of orthologOrganisms" [ngValue]="org">
        {{org.common_name}}
      </option>
    </select>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'small-ontology'">
    <select [(ngModel)]="selectedTerm"
            (ngModelChange)="smallOntologyChange()">
      <option [ngValue]="undefined">Choose ...</option>
      <option *ngFor = "let term of nodeConfig.terms" [ngValue]="term">
        {{term.name}}
      </option>
    </select>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'subset-select'">
    <select [(ngModel)]="selectedSubset"
            (ngModelChange)="subsetChange()">
      <option [ngValue]="undefined">Choose ...</option>
      <option *ngFor = "let subset of nodeConfig.subsets" [ngValue]="subset">
        {{removePrefix(subset.displayName)}}
      </option>
    </select>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'canned-queries'">
    <div *ngFor="let cannedQueryDetail of cannedQueryDetails">
      <a (click)="selectPredefinedQuery(cannedQueryDetail.queryId)"
         [innerHTML]="cannedQueryDetail.name"></a>
    </div>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'subset-input'">
    <app-query-subset-input [nodeConfig]="nodeConfig"
                            (nodeEvent)="subNodeEvent($event)">
    </app-query-subset-input>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'int-range' || nodeConfig.nodeType == 'float-range'"
       class="range">
    <app-query-range-node [nodeConfig]="nodeConfig"
                          (nodeEvent)="subNodeEvent($event)">
    </app-query-range-node>
  </div>

  <div class="genome-range" *ngIf="nodeConfig.id == 'genome_range'">
    <app-query-genome-range-node [nodeConfig]="nodeConfig"
                                 (nodeEvent)="subNodeEvent($event)">
    </app-query-genome-range-node>
  </div>

  <div *ngIf="nodeConfig.nodeType == 'sub-nodes'">
    <app-query-sub-nodes [nodeConfig]="nodeConfig"
                         (subNodeEvent)="subNodeEvent($event)">
    </app-query-sub-nodes>
  </div>
</div>
