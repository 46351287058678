<div>
  <app-social-contact></app-social-contact>
  <app-loading-spinner *ngIf="!subset && !apiError"></app-loading-spinner>
  <app-api-error-display *ngIf="apiError" [error]="apiError"></app-api-error-display>

  <div *ngIf="subset">
    <div class="details-page-name-and-id">
      <div *ngIf="subset.name.startsWith('non_slim_')">
        {{subset.display_name}}, not covered
        by the
        <span *ngIf="isGOSubset()">{{siteName}}
          <a routerLink="/browse-curation/fission-yeast-bp-go-slim-terms">GOslim</a>
        </span>
        <span *ngIf="!isGOSubset()">slim</span>
        categories: {{subset.elements.length}}
      </div>
      <div *ngIf="subsetDisplayName">
        {{subsetDisplayName}}<span *ngIf="subsetGeneCount > 0">: {{subsetGeneCount}}</span>
      </div>
    </div>

    <div class="actions">
      <span class="title">Actions: </span>
      <a *ngIf="queryBuilderRouterLink" routerLink="{{queryBuilderRouterLink}}">
        Use these genes in query...
      </a>
    </div>

    <app-genes-table [description]="subsetDisplayName"
                     [genesOrResults]="subset.elements"></app-genes-table>
  </div>
</div>
