<div class="range">
  <div *ngIf="nodeConfig.range_any_and_none" class="range-section">
    <input type="radio" id="range-any" [(ngModel)]="enabledRangeSection" value="any"/>
    <label for="range-any" class="range-label">Proteins with any {{nodeConfig.displayName}}</label>
  </div>
  <div *ngIf="nodeConfig.range_any_and_none" class="range-section">
    <input type="radio" id="range-none" [(ngModel)]="enabledRangeSection" value="none"/>
    <label for="range-none" class="range-label">Proteins with no {{nodeConfig.displayName}}</label>
  </div>

<div *ngIf="showPercentRange" class="range-section">
  <div *ngIf="nodeConfig.range_any_and_none">
    <input type="radio" id="range-percent-range" [(ngModel)]="enabledRangeSection"
      value="percent-range" />
    <label for="range-percent-range" class="percent-range-label">
      {{percentDisplayName}}:
    </label>
  </div>

  <div class="range-min-max">
    <input [disabled]="enabledRangeSection != 'percent-range'"
           id="percent-range-min"
           (change)="percentRangeMinToggled()"
           [(ngModel)]="percentRangeConstrainMin" type="checkbox" />
    <label for="percent-range-min" class="range-label">Minimum:</label>
    <input [disabled]="enabledRangeSection != 'percent-range' || !percentRangeConstrainMin"
           (change)="percentRangeStartChanged()"
           type="number"
           [(ngModel)]="percentRangeStart"
           min="{{percentRangeMin}}"
           step="1"
      placeholder="minimum ..." />
  </div>

  <div class="range-min-max">
    <input [disabled]="enabledRangeSection != 'percent-range'"
           id="percent-range-max"
           (change)="percentRangeMaxToggled()"
           [(ngModel)]="percentRangeConstrainMax" type="checkbox" />
    <label for="percent-range-max" class="range-label">Maximum:</label>
    <input [disabled]="enabledRangeSection != 'percent-range' || !percentRangeConstrainMax"
           (change)="percentRangeEndChanged()" type="number"
           [(ngModel)]="percentRangeEnd"
           min="{{percentRangeMin}}"
           step="1"
      placeholder="maximum ..." />
  </div>
</div>

  <div class="range-section">
    <div *ngIf="nodeConfig.range_any_and_none">
      <input *ngIf="this.nodeConfig.range_any_and_none" type="radio"
             id="range-range" [(ngModel)]="enabledRangeSection" value="range"/>
      <label for="range-range" class="range-label">
        Number of {{nodeConfig.displayName}} per protein:
      </label>
    </div>

    <div class="range-min-max">
      <input [disabled]="enabledRangeSection != 'range'"
             id="range-min"
             (change)="rangeMinToggled()"
             [(ngModel)]="rangeConstrainMin" type="checkbox"/>
      <label for="range-min" class="range-label">Minimum:</label>
      <input [disabled]="enabledRangeSection != 'range' || !rangeConstrainMin"
             (change)="rangeStartChanged()"
             type="number" [(ngModel)]="rangeStart" min="{{rangeMin}}"
             step="{{nodeConfig.nodeType == 'int-range' ? 1 : ''}}"
             placeholder="minimum ..."/>
    </div>
    <div class="range-min-max">
      <input [disabled]="enabledRangeSection != 'range'"
             id="range-max"
             (change)="rangeMaxToggled()"
             [(ngModel)]="rangeConstrainMax" type="checkbox"/>
      <label for="range-max" class="range-label">Maximum:</label>
      <input [disabled]="enabledRangeSection != 'range' || !rangeConstrainMax"
             (change)="rangeEndChanged()"
             type="number" [(ngModel)]="rangeEnd" min="{{rangeMin}}"
             step="{{nodeConfig.nodeType == 'int-range' ? 1 : ''}}"
             placeholder="maximum ..."/>
    </div>
  </div>

  <div *ngIf="nodeConfig.node_options" class="options-section">
    <div class="node-options-title">
      {{nodeConfig.node_options_title!}}:
    </div>
    <div class="node-option" *ngFor="let opt of nodeConfig.node_options">
      <input type="radio" id="range-{{opt.id}}" [(ngModel)]="rangeOption"
             value="{{opt.id}}" />
      <label for="range-{{opt.id}}" class="range-label">{{opt.display_name}}</label>
    </div>
  </div>

  <button type="button" class="btn btn-primary"
          title="Click to search"
          (click)="rangeSearch(nodeConfig)">Search</button>

</div>
