<div class="api-error">
  <div *ngIf="error.status == 404">
    <app-not-found [path]="path"></app-not-found>
  </div>
  <div *ngIf="error.status != 404">
    <div>There has been a server error while loading this page.</div>
    <div>
    Please try reloading or go to the
    <a routerLink="/" routerLinkActive="active" title="Home">main page</a>.
    </div>
    <div style="display: none">{{error}}</div>
  </div>
</div>
