<div id="term-genotype-view">
  <app-loading-spinner *ngIf="!termDetails"></app-loading-spinner>

  <div *ngIf="termDetails">
    <app-social-contact></app-social-contact>

    <app-term-page-summary [termDetails]="termDetails"></app-term-page-summary>

    <hr style="clear: both" />

    <div class="term-page-view-change">
      <a routerLink="/term/{{termDetails.termid}}"
         routerLinkActive="active">View all annotations for this term ...</a>
    </div>

    <div>
      <app-genotypes-table [legend]="'Single locus genotypes annotated with ' + termDetails.termid + ' or its descendants: ' + termDetails.single_locus_genotype_uniquenames.length"
                           [genotypes]="termDetails.single_locus_genotypes"></app-genotypes-table>
    </div>
  </div>
</div>
