<fieldset *ngIf="annotationTable && annotationTable.length > 0" class="annotation-table">
  <legend>
    Target of

    <app-help-link [route]="'/documentation/gene-page-target'"
                   [title]="'Click to view documentation for the &quot;Target of&quot; section'">
    </app-help-link>
  </legend>

  <div class="view-controls">
    <a *ngIf="currentViewState != TableViewState.Details" (click)="showDetails()">Show details ...</a>
    <a *ngIf="currentViewState == TableViewState.Details" (click)="showSummary()">Show summary ...</a>
  </div>

  <div class="send-to-query-builder">
    <div>
      Send causally upstream genes to the Advanced Search:
      <a (click)="sendToQueryBuilder('go')">Gene Ontology</a>
      |
      <a (click)="sendToQueryBuilder('phenotype')">Phenotype</a>
    </div>
  </div>

  <div class="annotation-table-container">
  <table>
    <thead>
      <tr>
        <th>Ontology</th>
        <th>Relationship</th>
        <th>Gene/genotype</th>
        <th>Product</th>
        <th *ngIf="currentViewState == TableViewState.Details">Reference</th>
      </tr>
    </thead>
    <tbody *ngIf="currentViewState == TableViewState.Details">
      <tr *ngFor="let annotation of displayTable">
        <td>{{annotation.ontologyLabel}}</td>
        <td>{{annotation.ext_rel_display_name}}</td>
        <td>
          <span *ngIf="!annotation.genotype">
            <app-gene-link class="app-link"
                           [long]="false" [gene]="annotation.gene"></app-gene-link>
          </span>
          <app-genotype-link class="app-link" *ngIf="annotation.genotype"
                             [genotype]="annotation.genotype"></app-genotype-link>
          &nbsp; <span *ngIf="annotation.gene">{{annotation.gene.product}}</span>
        </td>
        <td>
          <span>
            {{annotation.gene.product}}
          </span>
        </td>
        <td>
          <app-reference-short *ngIf="annotation.reference"
                               [reference]="annotation.reference"></app-reference-short>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="currentViewState == TableViewState.Summary">
      <tr *ngFor="let annotation of summaryTable">
        <td>{{annotation.ontologyLabel}}</td>
        <td>{{annotation.ext_rel_display_name}}</td>
        <td>
          <span>
            <app-gene-link class="app-link"
                           [long]="false" [gene]="annotation.gene"></app-gene-link>
          </span>
        </td>
        <td>
          <span>
            {{annotation.gene.product}}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</fieldset>
