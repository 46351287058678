<div class="not-found">
<div class="title centred">
  Page Not Found
</div>

<div class="centred">
  <img src="/assets/sad_pombe_404.png"/>
</div>

<div class="contact centred">
  Please
  <app-contact-email [linkName]="'contact the curators'"
                     [subject]="'Comment on a page-not-found: ' + path"></app-contact-email>
  if you feel this is an error.
</div>
</div>
