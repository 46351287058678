<div id="app-component">
  <div id="header" [style]="'background-image: url(../assets/' + headerBackgroundFileName + ');'">
    <div id="header-main">
      <div id="logo-and-slogan">
        <a id="header-anchor"></a>
        <div id="logo">
          <a routerLink="/" routerLinkActive="active" title="{{siteName}} home">
            <img src="assets/{{logoFileName}}" alt="{{siteName}} home" />
          </a>
          <span *ngIf="!deployConfigService.productionMode()" class="dev-mode-notice">
            DEV/TEST SERVER
          </span>
        </div>
      </div>
      <div id="header-search-box">
        <app-search-box></app-search-box>
      </div>
    </div>

    <app-main-nav-bar></app-main-nav-bar>
  </div>

<app-stats-ribbon class="stats-ribbon"></app-stats-ribbon>

<div id="content">

<router-outlet></router-outlet>

</div>

<div id="footer">
  <span class="footer-line" [innerHTML]="getFundersHtml()">
  </span>
  <span *ngIf="showElixirAndGbcMessage" class="footer-line">
    {{siteName}} is <a href="https://globalbiodata.org/scientific-activities/global-core-biodata-resources/">Global Core Biodata Resource</a>
    and an <a href="https://elixiruknode.org/services/">ELIXIR-UK Node Service</a>
  </span>
</div>

<div id="footer-logos">
  <a *ngFor="let conf of getLogoConfig()"
     href="{{conf.url}}">
    <img loading="lazy" src="assets/{{conf.image}}" alt="{{conf.alt_text}}"/>
  </a>
</div>

</div>
<!-- <cookie-law>This site uses cookies</cookie-law> -->
